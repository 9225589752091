import React from "react";
import "./ViewEvents.css";
import { CardGroup } from "react-bootstrap";
import { Link } from "react-router-dom";
// import events from "./events";
import newEvents from './newevents'

const ViewEvents = () => {
  return (
    <>
      <div className="artists">ARTISTS</div>

      <div className="view_artists_section">
        <Link className="btn_link" to="/artists">
          <button className="view_artists_btn">VIEW ALL ARTISTS</button>
        </Link>
        <br />
        <div className="black_bg"></div>
        <div className="section_view">
        <CardGroup className="card_group_event">
          {newEvents.map((event, index) => {
            return (
              <div className="card_event_event" key={index}>
                <div className="desk">
                  <h3>{event.event_name}</h3>
                  <div className="data">
                    <p>{event.venue}</p>
                    <p style={{fontWeight:700}}>{event.dates}</p> &nbsp;&nbsp;&nbsp;&nbsp;
                  </div>
                </div>
                <div className="btn">
                  <Link to={{ pathname: `${event.event_url}` }} target="_blank">
                    TICKETS
                  </Link>
                </div>
              </div>
            );
          })}
        
        </CardGroup>
        </div>
      </div>
    </>
  );
};

export default ViewEvents;
