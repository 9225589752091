import React from "react";
import { Link } from "react-router-dom";
// import samy from "../../images/samidohvid.mp4";
// import samy from "../../images/samy.mp4";
import "./Hero.css";

const Hero = () => {
  return (
    <>
      <div className="container">
        <div className="hero-main">
          <video className="videoTag" autoPlay loop muted>
            <source
              src="https://firebasestorage.googleapis.com/v0/b/ukulimasoko-32a56.appspot.com/o/Prince%20Indah%20-%20Mama%20Watoto%20(Sms%20Skiza%205435198%20to%20811).mkv?alt=media&token=e784a6a5-6a65-4a0a-b881-7b667bdf9c43"
              type="video/mp4"
            />
          </video>
          <div className="hero-text">
            <p>PRINCE INDAH USA TOUR</p>
          </div>
          <div className="hero-desc">
            <p>
              Tickets selling fast.
              <br />
              Click here To buy your tickets now
            </p>
          </div>
          <div className="hero-button">
            <Link
              to={{
                pathname:
                  "https://www.eventbrite.com/e/prince-indah-us-tour-dallas-tickets-312134722357",
              }}
              target="_blank"
            >
              <button className="tickets">GET TICKETS NOW</button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;
