import React from "react";
import "./Card.css";
import {Link} from "react-router-dom"
import cardData from '../Cards/cards'


const Card = () => {

  return (
    <>
      <ul className="cards">
        {cardData.map((card,index) =>{
          return (
            <li  className="cards_item" key={index}>
              <div className="card_image">
              <img src={card.image} alt="image_1" />
              <div className="card_content">
              <h2 className="card_title">
                    {card.name}
                </h2>
                <p className="card_text">{card.tour}</p>
                    <hr />
                    <Link to="/artists" className="link">
                    <p className="card_text_link">View Artist</p>
                    </Link> 
              </div>
              </div>
            </li>
          )
        })}
      </ul>
    </>
  );
};

export default Card;
