import React from 'react'
import ArtistsCards from '../components/Cards/ArtistsCards'
import './Artists.css'

const Artists = () => {
    return (
        <>
            <div className="artists-title">
                ARTISTS
            </div>
            <ArtistsCards />
        </>
    )
}

export default Artists
