import React from "react";
import "./EventCard.css";
import { CardGroup } from "react-bootstrap";
import { Link } from "react-router-dom";
import events from "../ViewEvents/events";
import newEvents from "../ViewEvents/newevents";


const EventCards = () => {
  return (
    <>
      <div className="events_title">EVENTS</div>
      <div className="section_view_event">
        <CardGroup className="card_group_event">
          {newEvents.map((event, index) => {
            return (
              <div className="card_event_event" key={index}>
                <div className="desk">
                  <h3>{event.event_name}</h3>
                  <div className="data">
                    <p>{event.venue}</p>
                    <p style={{fontWeight:700}}>{event.dates}</p> &nbsp;&nbsp;&nbsp;&nbsp;
                  </div>
                </div>
                <div className="btn">
                  <Link to={{ pathname: `${event.event_url}` }} target="_blank">
                    TICKETS
                  </Link>
                </div>
              </div>
            );
          })}
        
        </CardGroup>
      </div>
      <div className="events_title-past">PAST EVENTS</div>
      <div className="section_view_event">
        <CardGroup className="card_group_event">
          {events.map((event, index) => {
            return (
              <div className="card_event_event" key={index}>
                <div className="desk">
                  <h3>{event.event_name}</h3>
                  <div className="data">
                    <p>{event.venue}</p>
                    <p style={{fontWeight:700}}>{event.dates}</p> &nbsp;&nbsp;&nbsp;&nbsp;
                  </div>
                </div>
                <div className="btn">
                  {/* <Link to={{ pathname: `${event.event_url}` }} target="_blank">
                    TICKETS
                  </Link> */}
                  <p to={{ pathname: `${event.event_url}` }} target="_blank" style={{
                    color:"red"
                  }}>
                    Passed
                  </p>
                </div>
              </div>
            );
          })}
        
        </CardGroup>
      </div>
    </>
  );
};

export default EventCards;
