

const events = [
    {
      "id": 1,
      "event_name": "SAMIDOH USA TOUR",
      "dates": "NOV 6TH, 2021",
      "venue":"DALLAS",
      "event_url":"https://www.eventbrite.com/e/samidoh-us-tour-live-showdallas-texasit-wont-stopcant-stop-tickets-170706165737"

    },
    {
      "id": 2,
      "event_name": "SAMIDOH USA TOUR",
      "dates": "NOV 12TH, 2021",
      "venue":"MINNEAPOLIS",
      "event_url":"https://www.eventbrite.com/e/samidoh-live-in-minneapolis-tickets-188856353437"

    },
    {
        "id": 3,
        "event_name": "SAMIDOH USA TOUR",
        "dates": "NOV 13TH, 2021",
        "venue":"NEW JERSEY",
        "event_url":"https://www.eventbrite.com/e/samidoh-usa-tour-live-show-new-jersey-tickets-170714590937"
  
      },
      {
        "id": 4,
        "event_name": "SAMIDOH USA TOUR",
        "dates": "NOV 20TH, 2021",
        "venue":"HOUSTON",
        "event_url":"https://www.eventbrite.com/e/samidoh-usa-tour-live-show-houston-texas-tickets-170737850507"
  
      },
      {
        "id": 5,
        "event_name": "SAMIDOH USA TOUR",
        "dates": "26TH NOV,2021",
        "venue":"BIRMINGHAM ALABAMA",
        "event_url":"https://www.eventbrite.com/e/samidoh-us-tour-live-show-birmingham-tickets-170746265677"
  
      },
      {
        "id": 6,
        "event_name": "SAMIDOH USA TOUR",
        "dates": "3RD DEC, 2021",
        "venue":"DMV",
        "event_url":"https://eventbrite.com/e/samidoh-usa-tour-live-show-dmv-tickets-170759314707"
  
      },
      {
        "id": 7,
        "event_name": "SAMIDOH USA TOUR",
        "dates": "4TH DEC,2021",
        "venue":"ATLANTA GEORGIA",
        "event_url":"https://www.eventbrite.com/e/samidoh-usa-tour-live-show-atlantageorgia-tickets-170752955687"
  
      },
      {
        "id": 8,
        "event_name": "SAMIDOH USA TOUR",
        "dates": "10TH DECEMBER,2021",
        "venue":"BOSTON",
        "event_url":"https://www.eventbrite.com/e/samidoh-us-tour-boston-tickets-183972796587"
  
      },
      {
        "id": 9,
        "event_name": "SAMIDOH USA TOUR",
        "dates": "17TH DEC,2021",
        "venue":"PORTLAND,OREGON",
        "event_url":"https://www.eventbrite.com/e/samidoh-usa-tour-live-show-portland-oregon-tickets-177667948617"
  
      },
      {
        "id": 10,
        "event_name": "SAMIDOH USA TOUR",
        "dates": "18TH DEC,2021",
        "venue":"SEATTLE WASHINGTON",
        "event_url":"https://www.eventbrite.com/e/samidoh-usa-tour-live-show-seattle-tickets-178433608727"

      },
      

]


export default events;