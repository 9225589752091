import React from "react";
import ViewEvents from "../components/ViewEvents/ViewEvents";
import Hero from "./../components/NewHome/Hero";
import Card from "./../components/Cards/Card";
import Sponsor from "./../components/NewHome/Sponsor";
// import Promotion from './../components/NewHome/Promotion'

const Home = () => {
  return (
    <>
      <Hero />
      <Card />
      <ViewEvents />
      <Sponsor />
      {/* <Promotion /> */}
    </>
  );
};

export default Home;
