import styled from 'styled-components';


export const Container = styled.div`
  z-index: 1;
  width: 100%;
  max-width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 40px;
  padding-left: 40px;
 

  @media screen and (max-width: 991px) {
    padding-right: 30px;
    padding-left: 30px;
  }
`;

export const Button = styled.button`
  border-radius: 4px;
  background: ${({ primary }) => (primary ? '#fff' : '#fff')};
  white-space: nowrap;
  padding: ${({ big }) => (big ? '12px 64px' : '10px 20px')};
  color: #fff;
  font-size: ${({ fontBig }) => (fontBig ? '20px' : '16px')};
  outline: none;
  border: none;
  cursor: pointer;

  &: {
    transition: all 0.3s ease-out;
    background: #fff;
    background-color: ${({ primary }) => (primary ? '#000' : '#000')};
  }

  @media screen and (max-width: 960px) {
    width: 100%;
  }
`;
