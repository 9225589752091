import React from "react";
import { Form, Button, Card } from "react-bootstrap";
import '../ContactUs/Contact.css'
import { FaFacebook } from 'react-icons/fa';
import {Link} from "react-router-dom"

const ContactPage = () => {
  return (
    <>
    <div className="contact-page">
    CONTACT US
    </div>
    <div className="facebook_icon">
      <Link
       to={{ pathname: "https://www.facebook.com/pages/category/Party-Entertainment-Service/Jabali-Entertainment-109743828116569/" }}
       target="_blank"
      >
      <FaFacebook size={56} />
      </Link>
   
    </div>
      <Card className="contact-card">
        <Card.Body>
          <Card.Title className="contact_title">STAY IN TOUCH</Card.Title>
          <Form>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Control type="text" placeholder="Enter Your Name" />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Control
                type="text"
                placeholder="Enter Your Email Address"
              />
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Leave us your message here</Form.Label>
              <Form.Control as="textarea" rows={3} />
            </Form.Group>

            <Button type="submit" className="submit_btn">Submit</Button>
          </Form>
        </Card.Body>
      </Card>
    </>
  );
};

export default ContactPage;
