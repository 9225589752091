import React from "react";
import "./Card.css";
import { Link } from "react-router-dom";
import artistData from "./artists";

const ArtistsCards = () => {
  return (
    <>
      <ul className="cards">
        {artistData.map((artist, index) => {
          return (
            <li className="cards_item" key={index}>
              <div className="card">
                <img src={artist.image} alt="image_1" />
                <div className="card_content">
                  <h2 className="card_title">{artist.name}</h2>
                  <hr />
                  <div className="float_container">
                  <div className="float_child">
                    <p className="card_text_about">About</p>
                  </div>
                  <div className="float_child">
                    <Link to="/events">
                      <button className="view_event">View Events</button>
                    </Link>
                  </div>
                  </div>
                </div>
              </div>
            </li>
          );
        })}
      </ul>
    </>
  );
};

export default ArtistsCards;
