import React from "react";
// import { Container } from "react-bootstrap";
// import amg from '../../images/amg.png'
// import Promotion from "./Promotion";
import "./Sponsor.css"

const Sponsor = () => {
  return (
    <>
      {/* <Container className="sponsor_container">
       
        <br />
        <h3 className="sponsor"><a className="sponsor_link" href="https://www.amgrealtors.com/" target="_blank" rel="noreferrer">AMG Realtors</a>, Lead Sponsor, 2021 Samidoh U.S Tour</h3>
        <hr className="underline"/>
        <div className="sponsor_logo">
        </div>
        <img className="sponsor_img" src={amg} alt="amg"/> 
        <p className="click_btn">Click the button below to get a chance to win one of our five plots.</p>
        <Promotion />
      </Container> */}
    </>
  );
};

export default Sponsor;
