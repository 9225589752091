
const artistData = [
    {
      "id": 1,
      "name": "Samidoh",
      "tour": "USA Grand Tour",
      "image":"../../images/tour.jpeg"
     

    },
    {
      "id": 2,
      "name": "Prince Indah",
      "tour": "USA Grand Tour",
      "image":"../../images/indah.jpeg"

    },
    {
      "id": 3,
      "name": "Coming Soon",
      "tour": "Coming Soon",
      "image":"../../images/place.svg"

    },

]

export default artistData;