import React from "react";
import "./Footer.css";
// import { FaFacebook } from 'react-icons/fa';

const Footer = () => {
  return (
    <>
      <div className="footer_data">
        <div className="footer_title">STAY UPDATED</div>
        <div className="footer_desc">All Updates Straight to your inbox.</div>
        <div>
          <input
            type="email"
            className="footer_input"
            placeholder="Enter your email Address"
          />
        </div>
        <div className="footer_button">
          <button className="btn_subscribe">SUBSCRIBE</button>
        </div>
        <hr className="hr_line" />
        <div className="copyright">
          Copyright &copy; 2021 All Rights Reserved.
          {/* <FaFacebook /> */}
        </div>
      </div>
    </>
  );
};

export default Footer;
