const newEvents = [
  {
    id: 1,
    event_name: "PRINCE INDAH USA TOUR",
    dates: "APRIL 23, 2022",
    venue: "DALLAS",
    event_url:
      "https://www.eventbrite.com/e/prince-indah-us-tour-dallas-tickets-312134722357",
  },
  {
    id: 2,
    event_name: "PRINCE INDAH USA TOUR",
    dates: "APRIL 29, 2022",
    venue: "MINNEAPOLIS",
    event_url:
      "https://www.eventbrite.com/e/prince-indah-us-tour-minneapolis-mn-tickets-314959100147",
  },
  {
    id: 3,
    event_name: "PRINCE INDAH USA TOUR",
    dates: "MAY 7, 2022",
    venue: "ALLENTOWN",
    event_url:
      "https://www.eventbrite.com/e/prince-indah-us-tour-allentown-pa-tickets-312143237827",
  },
  {
    id: 4,
    event_name: "PRINCE INDAH USA TOUR",
    dates: "MAY 13, 2022",
    venue: "NY/NEW JERSEY",
    event_url:
      "https://www.eventbrite.com/e/prince-indah-us-tour-nynew-jersey-tickets-312143649057",
  },
  {
    id: 5,
    event_name: "PRINCE INDAH USA TOUR",
    dates: "MAY 14, 2022",
    venue: "HOUSTON",
    event_url:
      "https://www.eventbrite.com/e/prince-indah-us-tour-houston-tickets-312142264917",
  },
  {
    id: 6,
    event_name: "PRINCE INDAH USA TOUR",
    dates: "JUNE 4, 2022",
    venue: "SEATTLE",
    event_url:
      "https://www.eventbrite.com/e/prince-indah-us-tour-seattle-tickets-312142997107?aff=ebdsoporgprofile",
  },
];

export default newEvents;
