import React from 'react'
import ContactPage from '../components/ContactUs/ContactPage'


const Contact = () => {
    return (
       <>
       <ContactPage />
       </>
    )
}

export default Contact
