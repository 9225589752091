import React from 'react'
import EventCards from '../components/EventsCard/EventCards'
// import FinalCards from '../components/Cards/FinalCards.js'

const Events = () => {
    return (
        <div>
            <EventCards />
            {/* <EventCards /> */}
        </div>
    )
}

export default Events
