import "./App.css";
import Navbar from "./components/Navbar/Navbar";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Home from "./pages/Home";
import Artists from "./pages/Artists";
import Events from "./pages/Events";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Footer from "./components/Footer/Footer"


function App() {
  return (
    <Router>
      <Navbar />
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/artists" exact component={Artists} />
        <Route path="/events" exact component={Events} />
        <Route path="/about" exact component={About} />
        <Route path="/contact" exact component={Contact} />
      </Switch>
      <Footer />
    </Router>
  );
}

export default App;
